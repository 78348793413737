import moment from 'moment';
import lodash from 'lodash';
import * as firestore from 'firebase/firestore';

export const DATE_DEFAULT_FORMAT = 'DD.MM.YYYY';

export class DateConverter {

    public static toMilliseconds(date: Date | firestore.Timestamp) {
        if (date) {
            if (date instanceof Date) {
                return date.getTime();
            } else if (date instanceof firestore.Timestamp) {
                return date.toDate().getTime();
            } else {
                return date;
            }
        }
        return date;
    }

    public static toString(date: Date | firestore.Timestamp | string, format = DATE_DEFAULT_FORMAT) {
        if (!!date && !lodash.isNil(date)) {
            if (typeof date === 'string') {
                return date;
            }
            return moment(date instanceof Date ? date : date.toDate()).format(format);
        }
    }

    /**
     * Format milliseconds, date string or timestamp to date object
     * @param date Date in milliseconds, date string or timestamp
     * @param format Optional. Only needed if the date passed by params is a string and has a different format.
     */
    public static toDate(date: number | string | firestore.Timestamp, format = DATE_DEFAULT_FORMAT) {
        if (date instanceof firestore.Timestamp) {
            return date.toDate();
        } else if (typeof date === 'number') {
            return new Date(date);
        } else if (typeof date === 'string') {
            return moment(date, format).toDate();
        } else {
            return date;
        }
    }

    public static getGermanyDate = () => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        const year = new Intl.DateTimeFormat('de', {year: 'numeric'}).format(date);
        const month = new Intl.DateTimeFormat('de', {month: '2-digit'}).format(date);
        const day = new Intl.DateTimeFormat('de', {day: '2-digit'}).format(date);
        return `${day}.${month}.${year}`;
    }

    public static getConfirmationMessage(date) {
        if (date) {
            const validFrom = DateConverter.toString(date);
            if (validFrom === DateConverter.getGermanyDate()) {
                return 'payroll-bookkeeping.large-texts.change-status-confirmation-immediate';
            } else {
                return 'payroll-bookkeeping.large-texts.status-confirmation-for-new-line';
            }
        }
    }

}
